<template>
  <b-overlay :show="isLoading">
    {{ customForm }}
  </b-overlay>
</template>
<script>
import { User } from "@/models/user.model";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    customForm() {
      return this.$store.getters["CustomForms/current"];
    },
  },
  methods: {
    onPaginationData(data) {
      console.log(data);
    },
    async doFetch(id) {
      this.isLoading = true;
      if (id) {
        await this.$store.dispatch("Users/fetchById", id);
        this.user = this.$store.getters["Users/current"];
      } else {
        this.user = new User(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        );
      }

      this.isLoading = false;
    },
    async fetchMails() {
      this.isLoading = true;
      await this.$store.dispatch("Mails/fetchByRecipient", this.user?.email);
      this.isLoading = false;
    },
    async doSave() {
      let action = this.$route.query.id ? "Users/update" : "Users/create";

      this.$validator.validateAll().then(async (isValid) => {
        this.submitted = true;
        if (!isValid) {
          return;
        }
        this.isLoading = true;

        await this.$store
          .dispatch(action, this.user)
          .then((response) => {
            console.log(response);
            this.$root.$bvToast.toast("User saved!", { variant: "success" });
            this.saveDisabled = true;
            this.saveMessage = "";
            // this.$route.query.id = response.id
            this.doFetch(this.$route.query.id);
            this.submitted = false;
          })
          .catch((error) => {
            console.log(error);
            this.$root.$bvToast.toast(
              `There was an error saving the user. ${error.message}`,
              { variant: "danger" }
            );
          });

        this.isLoading = false;
      });
    },
  },
  async created() {
    await this.doFetch(this.$route.query.id);
    this.submitted = false;
    this.saveDisabled = true;
    this.saveMessage = "";
  },
};
</script>
<style lang="scss"></style>
